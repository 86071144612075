<template>
  <div id="app">
    <div id="nav">
      <Header />
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view />
    <BackTop></BackTop>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header
  },
  data() {
    return {
      activeColor: true
    };
  },
  created() {
    // console.log("start--------------");
    console.log(navigator);
    let flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // alert("手机端");
      // window.location.href = "http://yizhangxiao.com/m/index.html";
      // window.location.href = "/m/index.html";
    } else {
      // alert("pc端");
      // window.location.href = "http://yizhangxiao.com/index.html";
    }
  },
  mounted() {
    // setTimeout(() => {
    //   this.listenerFunction();
    // }, 100);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    handleScroll() {
      this.$nextTick(() => {
        //431 959 1461  1791 2519
        // console.log(this.$children[1].$children[1].$refs.block0.offsetTop);
        console.log(this.$store.state.indexActive);
        if (this.$store.state.flag) {
          console.log("window.pageYOffset");
          console.log(window.pageYOffset);
          if (window.pageYOffset > 431 && window.pageYOffset < 959) {
            console.log("1");
            this.$store.commit("editIndexActive", 0);
          } else if (window.pageYOffset > 959 && window.pageYOffset < 1461) {
            console.log("2");
            this.$store.commit("editIndexActive", 1);
          } else if (window.pageYOffset > 1461 && window.pageYOffset < 1791) {
            1;
            console.log("3");
            this.$store.commit("editIndexActive", 2);
          } else if (window.pageYOffset > 1791 && window.pageYOffset < 2519) {
            console.log("4");
            this.$store.commit("editIndexActive", 3);
          } else if (window.pageYOffset > 2519) {
            console.log("5");
            this.$store.commit("editIndexActive", 4);
          } else if (this.$store.state.showAbout) {
            console.log("6");
            this.$store.commit("editIndexActive", 5);
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.activeHeader {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff !important;
}
</style>
