<template>
  <div class="header-box">
    <div class="container-box head-group" v-if="!$store.state.showAbout">
      <div class="nav-dv">
        <div class="dv-img">
          <img src="~@/assets/img/images/slfq.png" alt="" />
        </div>
        <div class="ul">
          <li class="li" v-for="(item, index) in list" :key="item.id">
            <span
              @click="toLink(item)"
              :class="$route.name == item.name ? 'active' : ''"
            >
              {{ item.title }}
            </span>
            <div class="dv-menu-box">
              <img
                v-if="item.icon"
                class="down"
                src="~@/assets/img/images/down.png"
                alt=""
              />
              <div class="dv-menu-list" v-if="item.icon">
                <div
                  class="dv-menu-item"
                  v-for="(itemChild, idxChild) in pageList"
                  :key="idxChild"
                  @click="goAssignBlock('block' + idxChild, idxChild)"
                >
                  {{ itemChild.title }}
                </div>
                <!-- <div
                  class="dv-menu-item"
                  v-for="(itemChild, idxChild) in pageList"
                  :key="idxChild"
                  @click="goAssignBlock('block' + idxChild, idxChild,50)"
                  :divided="idxChild !== 0"
                >
                  {{ itemChild.title }}
                </div> -->
              </div>
            </div>
          </li>
        </div>
        <div class="contact">
          <span class="txt1">联系我们</span>
          <span class="txt1">020-8423 6325</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeColor: Boolean
  },
  data() {
    return {
      idx: 0,
      delay: 0,
      newActiveColor: "",
      list: [
        { id: 1, title: "首页", path: "/", name: "Home", icon: false },
        {
          id: 2,
          title: "远程协同平台",
          path: "/remoteCollaborative",
          name: "RemoteCollaborative",
          icon: true
        },
        {
          id: 3,
          title: "解决方案",
          path: "/solution",
          name: "Solution",
          icon: false
        },
        { id: 4, title: "关于我们", path: "/about", name: "About", icon: false }
      ],
      pageList: [
        { id: 1, title: "细胞学病理AI辅助诊断" },
        { id: 2, title: "远程病理诊断" },
        { id: 3, title: "远程会诊系统" },
        { id: 4, title: "远程医学教育" },
        { id: 5, title: "远程胎心监护" },
        { id: 6, title: "远程病理质控" },
        { id: 7, title: "健康宣教" },
        { id: 8, title: "医生社区" }
      ]
    };
  },
  methods: {
    toLink(item) {
      console.log(item.name, this.$route.name);
      if (item.name === this.$route.name) return;
      this.$router.push(item.path);
    },
    goAssignBlock(el, index) {
      if (this.$route.name !== "RemoteCollaborative") {
        this.delay = 100;
        this.$router.push({
          path: "/remoteCollaborative"
        });
      }
      setTimeout(() => {
        this.delay = 0;
        this.$parent.$children[2].$children[index].$refs[el].scrollIntoView(
          true
        );
      }, this.delay);
      // let _this = this;
      // setTimeout(() => {
      //   this.delay = 0;
      //   this.$nextTick(() => {
      //     let dom = _this.$parent.$children[2].$children[index].$refs;
      //     let windowH = window.innerHeight;
      //     let h = dom[el].offsetHeight;
      //     console.info(h);
      //     let t = dom[el].offsetTop;
      //     // let top = t - (windowH - h) / 2;
      //     let top = t;
      //     let scrollTop =
      //       window.pageYOffset ||
      //       document.documentElement.scrollTop ||
      //       document.body.scrollTop;
      //     let currentTop = scrollTop;
      //     let requestId;

      //     function step() {
      //       if (scrollTop < top) {
      //         if (currentTop <= top) {
      //           window.scrollTo(0, currentTop);
      //           requestId = window.requestAnimationFrame(step);
      //         } else {
      //           window.cancelAnimationFrame(requestId);
      //         }
      //         //向下滚动
      //         currentTop += speed;
      //       } else {
      //         if (top <= currentTop) {
      //           window.scrollTo(0, currentTop - speed);
      //           requestId = window.requestAnimationFrame(step);
      //         } else {
      //           window.cancelAnimationFrame(requestId);
      //         }
      //         currentTop -= speed;
      //       }
      //     }
      //     window.requestAnimationFrame(step);
      //   });
      // }, this.delay);
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  width: 100%;
  height: 64px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  .head-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
  }

  .nav-dv {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .ul {
      display: flex;
      height: 64px;
      .li {
        position: relative;
        height: 100%;
        display: flex;
        margin-right: 60px;
        align-items: center;
        cursor: pointer;
        span {
          display: flex;
          height: 64px;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #262626;
        }
        .dv-menu-box {
          height: 100%;
          display: flex;
          align-items: center;
          transition: all 1s;
          .dv-menu-list {
            transition: all 1s;
            display: none;
            flex-direction: column;
            background: #fff;
            width: 180px;
            position: absolute;
            top: 64px;
            left: 85%;
            border-radius: 4px;
            transform: translateX(-50%);
            box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.4);
            .dv-menu-item {
              height: 36px;
              line-height: 36px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #262626;
            }
          }
        }
        .dv-menu-box:hover .dv-menu-list {
          display: flex;
        }
        .down {
          margin-left: 6px;
          width: 28px;
          height: 28px;
        }
      }

      span.active {
        font-weight: bold;
        position: relative;
        &::before {
          content: " ";
          width: 100%;
          height: 4px;
          background: #0081f7;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
    .dv-img {
      // width: 110px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        // width: 188px;
        // height: 42px;
        width: 178px;
        height: 48px;
      }
    }
    .dv-img1 {
      width: 110px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 110px;
        height: 42px;
      }
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  .txt1 {
    font-size: 16px;
    color: #262626;
    font-weight: bold;
  }
}

// .dv-mask {
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.16);
//   position: fixed;
// }
</style>
