import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showPopup: false,
    showCode: false,
    phoneNumber: "",
    indexActive: 0,
    flag: true,
    showAbout:false,
  },
  mutations: {
    handleAbout(state, showAbout) {
      state.showAbout = showAbout;
    },
    handlePopup(state, popUpStatus) {
      state.showPopup = popUpStatus;
      
    },
    handleCode(state, codeStatus) {
      state.showCode = codeStatus;
    },
    getPhoneNumber(state, phoneNumber) {
      state.phoneNumber = phoneNumber;
    },
    editIndexActive(state, idx) {
      state.indexActive = idx;
      console.info(state.indexActive);
    },
    changeFlag(state, flag) {
      state.flag = flag;
    }
  },
  actions: {},
  modules: {}
});
