import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "./utils/lib-flexible";   //pc
// import "lib-flexible/flexible.js"; //mobile
// import "./utils/rem.js";
// Vue.prototype.$bus = new Vue();
import "./styles/reset.css";
import axios from "axios";
Vue.prototype.$axios = axios;
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
Vue.use(VueAwesomeSwiper);
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
Vue.use(ViewUI);
import animated from 'animate.css'
Vue.use(animated)


import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 999,
    title:false, 
  }
})
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
router.afterEach((to, form, next) => {
  window.scrollTo(0, 0);
});
